import {useNavigate, useParams} from "react-router";
import {SearchBarWrapper, SMILESChemicalStructure} from "../shared/components";
import {
    Button,
    Col,
    Input,
    InputNumber,
    notification,
    Radio,
    Row,
    Select,
    Slider,
    Spin,
    Switch,
    Table,
    Tooltip,
    Typography
} from "antd";
import {
    DEFAULT_FC,
    DEFAULT_P,
    DEFAULT_TYPE,
    DEFAULT_UNIQUE,
    ERROR_COLOR,
    HYPERBOLIC, PRECISION, PROTEIN_QUERY,
    SQUARE, TREATMENT_QUERY,
    X_SLIDER_MAX,
    X_SLIDER_MIN,
    Y_SLIDER_MAX,
    Y_SLIDER_MIN
} from "../shared/constants";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import _ from "lodash";
import {Heatmap, Volcano} from "./index";
import React, {useEffect, useState} from "react";
import {HeatMapOutlined, SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {connect} from "react-redux";
import {sorterHelper} from "../shared/utilities/SorterHelper";
import {getResultDataRequest} from "../features/experiments/experimentsActions";
import {addCustomCompound, addCustomProtein, setShowLabel} from "../features/results/resultsActions";
import PeptideBoxPlot from "../shared/components/PeptideBoxPlot";
import ApiService from '../features/apiService';

function Plots({token, experiments, results, addCustomProtein, addCustomCompound, getResultDataRequest, setShowLabel}) {
    const {Text} = Typography;

    const heatmapColors = [
        'Greys', 'YlGnBu', 'Greens', 'YlOrRd',
        'Bluered', 'RdBu', 'Reds', 'Blues', 'Picnic',
        'Rainbow', 'Portland', 'Jet', 'Hot', 'Blackbody',
        'Earth', 'Electric', 'Viridis', 'Cividis'
    ];
    const columns = [
        {
            title: 'Add to Custom Heatmap',
            key: 'custom_heatmap',
            width: 200,
            render: (text, record) => (
                <Button icon={<HeatMapOutlined />}
                        style={{marginLeft: 63}}
                        onClick={() => {
                            const {meta} = pageData;

                            addCustomProtein({
                                id: `${record.accession_name} (${record.gene_symbol})`,
                                key: String(record.accession_id)
                            });

                            addCustomCompound({
                                id: `${record.treatment_name} (${meta.name})`,
                                key: `${record.treatment_id}-${meta.id}`
                            });

                            notification.success({
                                placement: 'bottomRight',
                                message: 'Success',
                                description: 'Successfully added to custom heatmap!',
                                style: {backgroundColor: ERROR_COLOR}
                            })
                        }}
                />
            )
        },
        {
            title: 'Accession',
            dataIndex: 'accession_name',
            key: 'accession_name',
            sorter: (a, b) => sorterHelper(a.accession_name, b.accession_name),
            ...getColumnSearchProps('accession_name'),
        },
        {
            title: 'Gene Symbol',
            dataIndex: 'gene_symbol',
            key: 'gene_symbol',
            sorter: (a, b) => sorterHelper(a.gene_symbol, b.gene_symbol),
            ...getColumnSearchProps('gene_symbol'),
        },
        {
            title: 'Compound Name',
            dataIndex: 'compound_name',
            key: 'compound_name',
            sorter: (a, b) => sorterHelper(a.compound_name, b.compound_name),
            ...getColumnSearchProps('compound_name'),
        },
        {
            title: 'Treatment Name',
            dataIndex: 'treatment_name',
            key: 'treatment_name',
            sorter: (a, b) => sorterHelper(a.treatment_name, b.treatment_name),
            ...getColumnSearchProps('treatment_name'),
        },
        //region Problematic columns
        {
            title: <Text>Log<sub>2</sub> Fold Change</Text>,
            dataIndex: 'log_fc',
            key: 'log_fc',
            sorter: (a, b) => a.log_fc - b.log_fc,
            ...getColumnSearchProps('log_fc'),
            render: (text) => Number.parseFloat(text).toPrecision(PRECISION),
        },
        {
            title: <Text>Log<sub>10</sub> P-Value</Text>,
            dataIndex: 'neg_log10_p_value',
            key: 'neg_log10_p_value',
            sorter: (a, b) => a.neg_log10_p_value - b.neg_log10_p_value,
            ...getColumnSearchProps('neg_log10_p_value'),
            render: (text) => Number.parseFloat(text).toPrecision(PRECISION),
        },
        //endregion
        {
            title: `Pi`,
            dataIndex: 'pi',
            key: 'pi',
            sorter: (a, b) => a.pi - b.pi,
            ...getColumnSearchProps('pi'),
            render: (text) => Number.parseFloat(text).toPrecision(PRECISION),
        },
    ]
    const { dataType, id } = useParams();
    const navigate = useNavigate();

    //region State Variables
    const [volcanoP, setVolcanoP] = useState(DEFAULT_P);
    const [volcanoFC, setVolcanoFC] = useState(DEFAULT_FC);
    const [volcanoType, setVolcanoType] = useState(DEFAULT_TYPE);
    const [volcanoUnique, setVolcanoUnique] = useState(DEFAULT_UNIQUE);
    const [volcanoX, setVolcanoX] = useState(0);
    const [volcanoYMin, setVolcanoYMin] = useState();
    const [volcanoYMax, setVolcanoYMax] = useState();
    const [loading, setLoading] = useState(true);
    const [minCount, setMinCount] = useState(1);
    const [minCountEnabled, setMinCountEnabled] = useState(true);
    const [minReplicates, setMinReplicates] = useState(1);
    const [minReplicatesEnabled, setMinReplicatesEnabled] = useState(true);
    const [hits, setHits] = useState([]);
    const [hitData, setHitData] = useState([]);
    const [heatmapMax, setHeatmapMax] = useState(10);
    const [heatmapMin, setHeatmapMin] = useState(-10);
    const [heatmapColorSelected, setHeatmapColorSelected] = useState('RdBu')
    const [searchInput, setSearchInput] = useState();
    const [searchedColumn, setSearchedColumn] = useState();
    const [searchText, setSearchText] = useState();
    const [pageData, setPageData] = useState();
    const [hasUpdatedYRange, setHasUpdatedYRange] = useState(false);
    const [normalizeBoxPlot, setNormalizeBoxPlot] = useState(false);
    const [experimentCategory, setExperimentCategory] = useState('LFQ');
    const [experiment, setExperiment] = useState();
    //endregion

    //region Component Functions
    function getColumnSearchProps(dataIndex) {
        return {
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
                <div style={{padding: 8}}>
                    <Input
                        ref={node => {
                            setSearchInput(node);
                        }}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleTableSearch(selectedKeys, confirm, dataIndex)}
                        style={{width: 188, marginBottom: 8, display: 'block'}}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleTableSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{width: 90, marginRight: 8}}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{width: 90}}>
                        Reset
                    </Button>
                </div>
            ),

            filterIcon: filtered => (
                <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>
            ),

            onFilter: (value, record) =>
                record[dataIndex] ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase()) : '',

            onFilterDropdownOpenChange: visible => {
                if (visible) {
                    setTimeout(() => searchInput.select());
                }
            },

            render: text => (
                (searchedColumn === dataIndex && text) ?
                    <Highlighter
                        highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                    : text
            ),
        };
    }

    function onExperimentDropdownChange(experimentId) {
        const targetExperiment = experiments.searchedExperiment.compound.experiments.filter((experiment) => experiment.id === experimentId)[0];
        navigate(`/plots/treatment/${targetExperiment.treatment_id}`)
    }

    function getExperimentOptions() {
        let options = []
        if (Boolean(pageData)) {
            const data = 'compound' in pageData ? pageData.compound : pageData.protein

            data.experiments.forEach(element => {
                options.push(<Select.Option key={element.id} value={element.id}>{element.name}</Select.Option>)
            })
        }
        return options
    }

    function getTreatmentOptions() {
        let options = []
        let added = []
        const secondaryOptions = pageData && pageData.meta ? pageData.meta.compound : []

        secondaryOptions.forEach(element => {
            if (!(added.includes(`${element.id}/${element.treatment_id}`)) && element.experiment_id === experiment.id) {
                added.push(`${element.id}/${element.treatment_id}`);
                options.push(
                    <Select.Option
                        key={`${element.id}/${element.treatment_id}`}
                        value={`${element.id}/${element.treatment_id}`}
                    >
                        {element.treatment_name} ({element.name})
                    </Select.Option>
                );
            }
        })
        return options
    }

    function onTreatmentDropdownChange(inputValue) {
        setLoading(true);

        const selectedTreatmentId = _.split(inputValue, '/')[1];
        navigate(`/plots/treatment/${selectedTreatmentId}`)
    }

    function getTertiaryOptions() {
        let options = []

        if (Boolean(pageData)) {
            const data = 'compound' in pageData ? pageData.compound : pageData.protein;

            data.experiments.forEach(element => {
                options.push(<Select.Option key={element.id} value={element.id}>{element.cell_line} ({element.name})</Select.Option>)
            })
        }
        return options
    }

    function getHeatmapStyleOptions() {
        let options = []

        heatmapColors.forEach(element => {
            options.push(<Select.Option key={element} value={element}>{element}</Select.Option>)
        })
        return options
    }

    const updatePageData = AwesomeDebouncePromise(async (forceUpdateYRange=false) => {
        getResultDataRequest(
            token.token,
            id,
            dataType,
            minCount,
            minReplicates
        ).then((resp) => {
            if (resp) {
                setPageData(resp);
                setExperimentCategory(resp.meta.category);

                if (dataType === TREATMENT_QUERY) {
                    setExperiment(resp.compound.experiments.filter((value, index, array) => value.treatment_id == id)[0]);
                }

                const logFCValues = _.map(resp['data'], 'log_fc');
                if ((forceUpdateYRange || !hasUpdatedYRange) && logFCValues.length > 0) {
                    const calculatedVolcanoYMin = _.min(logFCValues) - 0.25;
                    const calculatedVolcanoYMax = _.max(logFCValues) + 1.0;

                    setVolcanoYMin(calculatedVolcanoYMin);
                    setVolcanoYMax(calculatedVolcanoYMax);
                    setHasUpdatedYRange(true);
                }

                updateChartsAndTable(resp);
            } else {
                setLoading(false);
            }
        });
    }, 500);

    function updateChartsAndTable(results) {
        const resultData = getResults(results);
        const hits = getHits(resultData);
        const hitData = getHitData(resultData);

        setHits(hits);
        setHitData(hitData);

        setLoading(false);
    }

    function getResults(data) {
        if (data === undefined) {
            return [];
        }

        if (dataType === TREATMENT_QUERY) {
            return _.filter(data.data, obj => {
                try {
                    return obj.unique_peptides >= volcanoUnique
                } catch {
                    return false
                }
            });
        }

        return data.data;
    }

    function isHit(record) {
        const useDefault = _.isNil(volcanoFC) || _.isNil(volcanoP) || _.isNil(volcanoType);

        if (useDefault) {
            return record.default_hit && record.unique_peptides >= volcanoUnique;
        } else {
            if (volcanoType === SQUARE) {
                return (record.neg_log10_p_value <= volcanoP) &&
                    (record.log_fc >= 0 ?
                        record.log_fc >= Math.abs(Math.log2(volcanoFC)) :
                        record.log_fc <= (Math.abs(Math.log2(volcanoFC)) * -1));

            } else {
                const piUp = Math.abs(Math.log2(volcanoFC) * volcanoP);
                const piDown = Math.abs(Math.log2(volcanoFC) * volcanoP) * -1;

                return record.log_fc >= 0 ? record.pi >= piUp : record.pi <= piDown;
            }
        }
    }

    function getHits(resultData) {
        if (resultData !== undefined && resultData.length > 0) {
            return _.filter(resultData, obj => isHit(obj));
        }
        return [];
    }

    function getHitData(resultData) {
        return _.map(resultData, obj => isHit(obj))
    }

    function handleTableSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    }

    function handleReset(clearFilters) {
        clearFilters();
        this.setState({searchText: ''});
    }
    //endregion

    useEffect(() => {
        if (dataType === TREATMENT_QUERY) {
            ApiService.getExperimentCategoryDefaults(token.token, experimentCategory)
                .then(resp => {
                    if (resp !== undefined) {
                        setMinCount(resp.min_count_filter);
                        setMinCountEnabled(resp.min_count_filter_enabled);
                        setMinReplicates(resp.min_replicates_filter);
                        setMinReplicatesEnabled(resp.min_replicates_filter_enabled);
                    }
                });
        }
    }, [experimentCategory])

    useEffect(() => {
        if (dataType !== TREATMENT_QUERY) {
            setMinCount(1);
            setMinReplicates(1);
        }
        setLoading(true);
        updatePageData(true).then(() => setLoading(false));
    }, [
        id,
        dataType,
    ])

    useEffect(() => {
        updatePageData().then(() => setLoading(false));
    }, [
        minCount,
        minReplicates
    ]);

    useEffect(() => {
        updateChartsAndTable(pageData);
    }, [
        volcanoP,
        volcanoFC,
        volcanoType,
        volcanoUnique,
        volcanoX,
        volcanoYMin,
        volcanoYMax,
        heatmapMax,
        heatmapMin,
        heatmapColorSelected,
    ]);

    if (loading) {
        return <Spin />
    }

    let initialSearchValue = '';
    if (dataType === TREATMENT_QUERY && pageData !== undefined && pageData.compound !== undefined) {
        initialSearchValue = pageData.compound.name;
    } else if (pageData !== undefined && dataType === PROTEIN_QUERY) {
        initialSearchValue = `${pageData.meta.filter_label.split(';')[0]} (${pageData.meta.filter_name})`;
    }

    return <>
        <SearchBarWrapper initialSearchValue={initialSearchValue} hits={hits}>
            <Row gutter={8} style={Boolean(pageData) ? {marginTop: 15} : {display: 'none'}}>
                {dataType === TREATMENT_QUERY && experiment !== undefined ? <>
                    <Col span={6}>
                        <div className='searchDropdownDiv'>
                            <Tooltip title='Multi-plex experiments including this compound'><span
                                className='selectTitle'>Experiment</span></Tooltip>
                            <Select
                                className={'filterDropdown'}
                                value={experiment.name}
                                optionFilterProp="children"
                                onChange={onExperimentDropdownChange}
                                filterOption={(input, option) => {
                                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }}
                            >
                                {getExperimentOptions()}
                            </Select>
                            <Tooltip title='All treatments in this multi-plex experiment'><span
                                className='selectTitle'>Treatment</span></Tooltip>
                            <Select
                                className={'filterDropdown'}
                                value={pageData && pageData.compound ? pageData.compound.name : ''}
                                optionFilterProp="children"
                                onChange={onTreatmentDropdownChange}
                                filterOption={(input, option) => {
                                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }}
                            >
                                {getTreatmentOptions()}
                            </Select>
                            <Tooltip title='All cell lines with this compound'>
                                    <span className='selectTitle'>
                                        Cell Line
                                    </span>
                            </Tooltip>
                            <Select
                                className={'filterDropdown'}
                                value={experiment.cell_line}
                                optionFilterProp="children"
                                onChange={onExperimentDropdownChange}
                                filterOption={(input, option) => {
                                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }}
                            >
                                {getTertiaryOptions()}
                            </Select>
                            <span className='selectTitle'>Concentration (µM)</span>
                            <Input
                                className='searchDropdown'
                                value={pageData && pageData.meta ? pageData.meta.concentration : ''}
                                disabled={true}
                                style={{marginLeft: 10, width: '92%'}}
                            >
                            </Input>
                            <span className='selectTitle'>Time (min)</span>
                            <Input
                                className='searchDropdown'
                                value={pageData && pageData.meta ? pageData.meta.time : ''}
                                disabled={true}
                                style={{marginLeft: 10, width: '92%'}}
                            >
                            </Input>
                        </div>
                        <div className='customControls'>
                            <span className='selectTitle' style={{
                                display: 'table',
                                margin: '0 auto'
                            }}>
                                Compound Chemical Structure
                            </span>
                            <SMILESChemicalStructure chemicalStructureSVG={pageData && pageData.compound ? pageData.compound.chemicalStructureSVG : null}/>
                        </div>

                        <div className='searchDropdownDiv'>
                            <div style={{paddingBottom: "1em"}}>
                                <div style={{
                                    display: "inline-block",
                                    width: "50%"
                                }}>
                                    <span className='selectTitle' style={{
                                        display: 'table',
                                        margin: '0 auto'
                                    }}>
                                        Show Gene Symbol
                                    </span>

                                    <div
                                        style={{
                                            marginTop: 5,
                                            marginBottom: -5,
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}>
                                        <Switch checked={results.showLabel} onChange={setShowLabel}/>
                                    </div>
                                </div>

                                <div style={{
                                    display: "inline-block",
                                    width: "50%"
                                }}>
                                    <span className='selectTitle' style={{
                                        display: 'table',
                                        margin: '0 auto'
                                    }}>
                                        Normalize Box Plot
                                    </span>

                                    <div
                                        style={{
                                            marginTop: 5,
                                            marginBottom: -5,
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}>
                                        <Switch
                                            checked={normalizeBoxPlot}
                                            onChange={setNormalizeBoxPlot}
                                        />
                                    </div>
                                </div>
                            </div>

                            <span className='cutoffTitle' style={{
                                display: 'table',
                                margin: '0 auto'
                            }}>
                                Hit Cut-off Settings
                            </span>
                            <span className='selectTitle' style={{
                                display: 'table',
                                margin: '0 auto'
                            }}>
                                Cut-off Shape
                            </span>
                            <div
                                style={{marginTop: 5, marginBottom: -5, display: 'flex', justifyContent: 'center'}}>
                                <Radio.Group onChange={e => {
                                    setVolcanoType(e.target.value);
                                }} value={volcanoType}>
                                    <Radio.Button value={HYPERBOLIC}>
                                        <Tooltip title={<Text style={{color: 'white'}}>Cutoff defined by k =
                                            log<sub>10</sub> (P-Value) x log<sub>2</sub>(Fold Change)</Text>}>
                                            Hyperbolic
                                        </Tooltip>
                                    </Radio.Button>

                                    <Radio.Button value={SQUARE}>
                                        <Tooltip title={<Text style={{color: 'white'}}>Cutoffs defined by x =
                                            log<sub>10</sub> (P-Value) and y = log<sub>2</sub>(Fold Change)</Text>}>
                                            Square
                                        </Tooltip>
                                    </Radio.Button>
                                </Radio.Group>
                            </div>

                            <br/>

                            <span className='selectTitle'>
                                <Tooltip title='Log-transformed P-value from a moderated t-test'>Log<sub>10</sub> P-Value</Tooltip>
                            </span>
                            <Row>
                                <Col span={16}>
                                    <Slider
                                        style={{marginLeft: 16}}
                                        step={1}
                                        min={X_SLIDER_MIN}
                                        max={X_SLIDER_MAX}
                                        onChange={value => {
                                            setVolcanoP(value);
                                        }}
                                        value={volcanoP}
                                        onChangeComplete={value => {
                                            setVolcanoP(value)
                                        }}
                                    />
                                </Col>
                                <Col span={8}>
                                    <InputNumber
                                        style={{width: '75%', marginLeft: 16}}
                                        min={X_SLIDER_MIN}
                                        max={X_SLIDER_MAX}
                                        step={0.1}
                                        value={volcanoP}
                                        onChange={value => {
                                            if (!isNaN(value)) {
                                                AwesomeDebouncePromise(setVolcanoP(value ? value : 0), 500)
                                            } else {
                                                console.log(`Bad field: ${value}`)
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                            <span className='selectTitle'><Tooltip
                                title='Absolute fold change in protein abundance for treatment vs control'>Fold Change</Tooltip></span>
                            <Row>
                                <Col span={16}>
                                    <Slider
                                        style={{marginLeft: 16}}
                                        step={0.25}
                                        min={1}
                                        max={10}
                                        value={volcanoFC}
                                        onChange={setVolcanoFC}
                                        onChangeComplete={setVolcanoFC}
                                    />
                                </Col>
                                <Col span={8}>
                                    <InputNumber
                                        style={{width: '75%', marginLeft: 16}}
                                        min={0}
                                        max={Y_SLIDER_MAX}
                                        step={0.1}
                                        value={volcanoFC}
                                        onChange={value => {
                                            if (!isNaN(value)) {
                                                AwesomeDebouncePromise(setVolcanoFC(value ? value : 0), 500)
                                            } else {
                                                console.log(`Bad field: ${value}`)
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                            <span className='selectTitle'><Tooltip
                                title='Number of unique peptides used to identify this protein'>Unique Peptides</Tooltip></span>
                            <Row>
                                <Col span={16}>
                                    <Slider
                                        style={{marginLeft: 16}}
                                        step={1}
                                        min={1}
                                        max={50}
                                        value={volcanoUnique}
                                        onChange={setVolcanoUnique}
                                        onChangeComplete={setVolcanoUnique}
                                    />
                                </Col>
                                <Col span={8}>
                                    <InputNumber
                                        style={{width: '75%', marginLeft: 16}}
                                        min={1}
                                        max={50}
                                        value={volcanoUnique}
                                        onChange={value => {
                                            if (!isNaN(value)) {
                                                setVolcanoUnique(value ? value : 0);
                                            } else {
                                                console.log(`Bad field: ${value}`)
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                            <span className='selectTitle' style={{
                                display: minCountEnabled || minReplicatesEnabled ? 'table' : 'none',
                                margin: '0 auto'
                            }}>
                                Counts / Replicate
                            </span>
                            <Row style={{display: minCountEnabled || minReplicatesEnabled ? 'flex' : 'none'}}>
                                <Col span={8} style={{margin: 'auto'}}>
                                    <InputNumber
                                        style={{width: '75%', marginLeft: 16}}
                                        min={1}
                                        step={1}
                                        value={minCount}
                                        disabled={!minCountEnabled}
                                        onChange={value => {
                                            if (!isNaN(value) && value !== null) {
                                                setMinCount(value);
                                            }
                                        }}
                                    />
                                </Col>
                                <Col span={8} style={{margin: 'auto'}}>
                                    <InputNumber
                                        style={{width: '75%', marginLeft: 16}}
                                        min={1}
                                        step={1}
                                        value={minReplicates}
                                        disabled={!minReplicatesEnabled}
                                        onChange={value => {
                                            if (!isNaN(value)) {
                                                setMinReplicates(value);
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>

                            <br/>

                            <span className='selectTitle'>X-axis Limit</span>
                            <Row>
                                <Col span={16}>
                                    <Slider
                                        style={{marginLeft: 16}}
                                        step={0.1}
                                        min={X_SLIDER_MIN}
                                        max={X_SLIDER_MAX}
                                        value={volcanoX}
                                        onChange={setVolcanoX}
                                        onChangeComplete={setVolcanoX}
                                    />
                                </Col>
                                <Col span={8}>
                                    <InputNumber
                                        style={{width: '75%', marginLeft: 16}}
                                        min={X_SLIDER_MIN}
                                        max={X_SLIDER_MAX}
                                        step={0.1}
                                        value={volcanoX}
                                        onChange={value => {
                                            if (!isNaN(value)) {
                                                setVolcanoX(value);
                                            } else {
                                                console.log(`Bad field: ${value}`)
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                            <span className='selectTitle'>Y-axis Limits</span>
                            <Row>
                                <Col span={8}>
                                    <InputNumber
                                        style={{width: '75%', marginLeft: 16}}
                                        min={Y_SLIDER_MIN}
                                        max={Y_SLIDER_MAX}
                                        step={0.1}
                                        value={volcanoYMin}
                                        onChange={
                                            value => {
                                                if (!isNaN(value)) {
                                                    const rounded = _.round(value ? value : 0, 1)
                                                    setVolcanoYMin(rounded)
                                                } else {
                                                    console.log(`Bad field: ${value}`)
                                                }
                                            }
                                        }
                                    />
                                </Col>
                                <Col span={8}>
                                    <Slider
                                        style={{marginLeft: 16}}
                                        range={true}
                                        step={0.1}
                                        min={Y_SLIDER_MIN}
                                        max={Y_SLIDER_MAX}
                                        value={[volcanoYMin, volcanoYMax]}
                                        onChange={value => {
                                            setVolcanoYMin(value[0]);
                                            setVolcanoYMax(value[1]);
                                        }}
                                        onChangeComplete={value => {
                                            setVolcanoYMin(value[0]);
                                            setVolcanoYMax(value[1]);
                                        }}
                                    />
                                </Col>
                                <Col span={8}>
                                    <InputNumber
                                        style={{width: '75%', marginLeft: 16}}
                                        min={Y_SLIDER_MIN}
                                        max={Y_SLIDER_MAX}
                                        step={0.1}
                                        value={volcanoYMax}
                                        onChange={
                                            value => {
                                                if (!isNaN(value)) {
                                                    const rounded = _.round(value ? value : 0, 1)
                                                    setVolcanoYMax(rounded);
                                                } else {
                                                    console.log(`Bad field: ${value}`)
                                                }
                                            }
                                        }
                                    />
                                </Col>
                            </Row>

                        </div>
                        <div className='searchDropdownDiv'>
                        <span className='selectTitle'>Heatmap Color Bar Range</span>
                            <Select
                                className={'filterDropdown'}
                                value={heatmapColorSelected}
                                optionFilterProp="children"
                                onChange={setHeatmapColorSelected}
                                filterOption={(input, option) => {
                                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }}
                            >
                                {getHeatmapStyleOptions()}
                            </Select>
                            <span className='selectTitle'>Heatmap Range:</span>
                            <Row>
                                <Col span={8}>
                                    <InputNumber
                                        style={{width: '75%', marginLeft: 16}}
                                        min={-10}
                                        max={10}
                                        step={0.1}
                                        value={heatmapMin}
                                        onChange={
                                            value => {
                                                if (!isNaN(value)) {
                                                    const rounded = _.round(value ? value : 0, 1)
                                                    setHeatmapMin(rounded)
                                                } else {
                                                    console.log(`Bad field: ${value}`)
                                                }
                                            }
                                        }
                                    />
                                </Col>
                                <Col span={8}>
                                    <Slider
                                        range={true}
                                        value={[heatmapMin, heatmapMax]}
                                        step={0.1}
                                        min={-10}
                                        max={10}
                                        onChange={value => {
                                            setHeatmapMin(value[0])
                                            setHeatmapMax(value[1])
                                        }}
                                        onChangeComplete={value => {
                                            setHeatmapMin(value[0])
                                            setHeatmapMax(value[1])
                                        }}
                                    />
                                </Col>
                                <Col span={8}>
                                    <InputNumber
                                        style={{width: '75%', marginLeft: 16}}
                                        min={-10}
                                        max={10}
                                        step={0.1}
                                        value={heatmapMax}
                                        onChange={
                                            value => {
                                                if (!isNaN(value)) {
                                                    const rounded = _.round(value ? value : 0, 1)
                                                    setHeatmapMax(rounded)
                                                } else {
                                                    console.log(`Bad field: ${value}`)
                                                }
                                            }
                                        }
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </> : <></>}
                <Col span={pageData && pageData.protein ? 24 : 18}>
                    <Row gutter={8}>
                        {pageData && (pageData.compound || pageData.protein) ?
                            <>
                                <Volcano
                                    hits={hitData}
                                    token={token}
                                    pageData={pageData}
                                    volcanoUnique={volcanoUnique}
                                    volcanoFC={volcanoFC}
                                    volcanoP={volcanoP}
                                    volcanoType={volcanoType}
                                    volcanoX={volcanoX}
                                    volcanoYMin={volcanoYMin}
                                    volcanoYMax={volcanoYMax}
                                    handleDataPointClicked={(url) => {
                                        setPageData(undefined);
                                        setLoading(true);
                                        navigate(url);
                                    }}
                                />

                                {
                                    dataType === TREATMENT_QUERY ?
                                        <PeptideBoxPlot
                                            experimentId={pageData.meta.id}
                                            minCount={minCount}
                                            minReplicates={minReplicates}
                                            normalized={normalizeBoxPlot}
                                        />
                                        : <></>
                                }
                            </>
                            : <Spin />}
                    </Row>
                    <Row>
                        {hits.length && pageData && !Boolean(pageData.meta.filter_label) ?
                            <Heatmap
                                token={token}
                                colorScheme={heatmapColorSelected}
                                treatmentId={id}
                                minCount={minCount}
                                minReplicates={minReplicates}
                                foldChangeThreshold={volcanoFC}
                                maxLog10PValue={volcanoP}
                                minUniquePeptides={volcanoUnique}
                                volcanoType={volcanoType}
                            /> : null}
                    </Row>
                </Col>
            </Row>

            {
                hits.length && pageData && !Boolean(pageData.meta.filter_label) ?
                    <Row>
                        <Table
                            style={{marginTop: 30, width: '100%'}}
                            columns={columns}
                            dataSource={_.map(hits, (hit, index) => {
                                return {
                                    ...hit,
                                    key: index
                                };
                            })}
                        />
                    </Row>
                : null
            }
        </SearchBarWrapper>
    </>
}

function mapStateToProps({ token, experiments, results }) {
    return {
        token,
        experiments,
        results
    }
}

export default connect(mapStateToProps, {
    getResultDataRequest,
    addCustomProtein,
    addCustomCompound,
    setShowLabel
})(Plots);