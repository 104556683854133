import { produce } from 'immer'
import { SET_ACCESSION_ID, SET_HIDDEN_COMPOUNDS } from "./peptideBoxPlotConstants";

const INITIAL_STATE = {
    geneId: undefined,
    hiddenCompounds: [],
}

export default produce ((draft = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_ACCESSION_ID:
            const { accessionId } = action
            draft['accessionId'] = accessionId
            break

        case SET_HIDDEN_COMPOUNDS:
            const { hiddenCompounds } = action
            draft['hiddenCompounds'] = hiddenCompounds
            break

        default:
            break
        }

    return draft
})