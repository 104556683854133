import { SET_ACCESSION_ID, SET_HIDDEN_COMPOUNDS } from './peptideBoxPlotConstants'

export const setAccessionId = (accessionId) => {
    return {
        type: SET_ACCESSION_ID,
        accessionId,
    }
}

export const setHiddenCompounds = (hiddenCompounds) => {
    return {
        type: SET_HIDDEN_COMPOUNDS,
        hiddenCompounds,
    }
}