import axios from 'axios'
import { notification } from 'antd'
import { BACKEND_URL, ERROR_COLOR } from '../shared/constants'
import {handleError} from "../shared/utilities/ErrorHandler";

const GET_METHOD = 'get'
const POST_METHOD = 'post'
const PATCH_METHOD = 'patch'
const DELETE_METHOD = 'delete'

class ApiService {
    constructor(baseUrl) {
        this.baseUrl = baseUrl
    }

    clusterHeatmapRequest = async (token, accessions, compoundExperiments) => {
        return await axios({
            method: POST_METHOD,
            url: `${BACKEND_URL}/custom_heatmap`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            data: {
                accessions,
                compoundExperiments,
            },
            timeout: 55000,
        }).catch(handleError);
    }

    addExperimentToGroup = async (token, experimentId, groupId) => {
        return await axios({
            method: POST_METHOD,
            url: `${BACKEND_URL}/group_experiments/${experimentId}/${groupId}`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            timeout: 55000,
        }).then((addResponse) => {
            notification.success({
                placement: 'bottomRight',
                message: 'Update Success',
                description: `Successfully added experiment ${addResponse.data.experiment.name} to group ${addResponse.data.group.name}`,
                style: {backgroundColor: ERROR_COLOR}
            });
            return addResponse;
        }).catch(handleError);
    }

    removeExperimentFromGroup = async (token, experimentId, groupId) => {
        return await axios({
            method: DELETE_METHOD,
            url: `${BACKEND_URL}/group_experiments/${experimentId}/${groupId}`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            timeout: 55000,
        }).then((removeResponse) => {
            notification.success({
                placement: 'bottomRight',
                message: 'Remove Success',
                description: `Successfully removed experiment ${removeResponse.data.experiment.name} from group ${removeResponse.data.group.name}`,
                style: {backgroundColor: ERROR_COLOR}
            });
            return removeResponse;
        }).catch(handleError);
    }

    createExperiment = async (token, data) => {
        return await axios({
            method: POST_METHOD,
            url: `${BACKEND_URL}/experiments`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            timeout: 55000,
            data,
        }).then((createResponse) => {
            notification.success({
                placement: 'bottomRight',
                message: 'Create Success',
                description: `Successfully created ${createResponse.data.name}`,
                style: {backgroundColor: ERROR_COLOR}
            });
            return createResponse;
        }).catch(handleError);
    }

    bulkCreateExperiment = async (token, data) => {
        return await axios({
            method: POST_METHOD,
            url: `${BACKEND_URL}/experiments_bulk`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            timeout: 55000,
            data,
        }).then((createResponse) => {
            notification.success({
                placement: 'bottomRight',
                message: 'Create Success',
                description: `Successfully created experiments.`,
                style: {backgroundColor: ERROR_COLOR}
            });
            return createResponse;
        }).catch(handleError);
    }

    updateExperiment = async (token, data) => {
        const id = data.id;
        delete data.id;

        return await axios({
            method: PATCH_METHOD,
            url: `${BACKEND_URL}/experiments/${id}`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            timeout: 55000,
            data,
        }).then((updateResponse) => {
            notification.success({
                placement: 'bottomRight',
                message: 'Update Success',
                description: `Successfully updated ${updateResponse.data.name}`,
                style: {backgroundColor: ERROR_COLOR}
            });
            return updateResponse;
        }).catch(handleError);
    }

    getExperiments = async (token) => {
        return await axios.get(`${BACKEND_URL}/experiments`, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            timeout: 55000,
            data: null
        }).catch(handleError);
    }

    getSingleExperiment = async (token, experimentId) => {
        return await axios({
            method: GET_METHOD,
            url: `${BACKEND_URL}/experiments/${experimentId}`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            timeout: 55000,
        }).catch(handleError);
    }

    getResultData = async (token, targetId, queryType, minCount, minReplicates) => {
        let url = `${BACKEND_URL}/results/${queryType}/${targetId}`;

        if (minCount !== undefined && minReplicates !== undefined) {
            url += `/${minCount}/${minReplicates}`;
        }

        return await axios({
            method: GET_METHOD,
            url,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            timeout: 55000,
        }).catch(handleError);
    }

    getHeatmapData = async (token, experimentId, minCount, minReplicates, foldChangeThreshold, maxLog10PValue, minUniquePeptides, cutoffShape) => {
        const url = new URL(`${BACKEND_URL}/heatmap/${experimentId}`);

        return await axios({
            method: POST_METHOD,
            url: url.toString(),
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            timeout: 55000,
            data: {
                minCount,
                minReplicates,
                foldChangeThreshold,
                maxLog10PValue,
                minUniquePeptides,
                cutoffShape
            }
        }).catch(handleError);
    }

    createConfiguration = async (token, data) => {
        return await axios({
            method: POST_METHOD,
            url: `${BACKEND_URL}/system_configuration`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            timeout: 55000,
            data,
        }).then((createResponse) => {
            notification.success({placement: 'bottomRight', message: 'Create Success', description: `Successfully created system configuration.`, style: {backgroundColor: ERROR_COLOR} });
            return createResponse;
        }).catch(handleError);
    }
    deleteConfiguration = async (token, configId) => {
        return await axios({
            method: DELETE_METHOD,
            url: `${BACKEND_URL}/system_configuration/${configId}`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            timeout: 55000,
        }).then((deleteResponse) => {
            notification.success({placement: 'bottomRight', message: 'Delete Success', description: 'Successfully deleted system configuration.', style: {backgroundColor: ERROR_COLOR} })
            return deleteResponse
        }).catch(handleError);
    }

    updateConfiguration = async (token, data) => {
        const id = data.id
        delete data.id

        return await axios({
            method: PATCH_METHOD,
            url: `${BACKEND_URL}/system_configuration/${id}`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            timeout: 55000,
            data,
        }).then((updateResponse) => {
            notification.success({placement: 'bottomRight', message: 'Update Success', description: 'Successfully updated system configuration.', style: {backgroundColor: ERROR_COLOR} });
            return updateResponse;
        }).catch(handleError);
    }

    getConfigurations = async (token) => {
        return await axios({
            method: GET_METHOD,
            url: `${BACKEND_URL}/system_configuration`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            timeout: 55000,
        }).catch(handleError);
    }

    getExperimentCategories = async (token) => {
        return await axios({
            method: GET_METHOD,
            url: `${BACKEND_URL}/experiment_categories`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            timeout: 55000,
        }).then(resp => resp.data)
            .catch(handleError);
    }

    getExperimentCategoryDefaults = async (token, experimentCategory) => {
        return await axios({
            method: GET_METHOD,
            url: `${BACKEND_URL}/experiment_category/${experimentCategory}`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            timeout: 55000,
        }).then(resp => resp.data)
            .catch(handleError);
    }

    approveExperiment = async (token, experimentId) => {
        return await axios({
            method: POST_METHOD,
            url: `${BACKEND_URL}/experiments/${experimentId}/approve`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            timeout: 55000,
        }).then(resp => resp.data)
            .catch(handleError);
    }

    updateUser = async (token, data) => {
        const id = data.id
        delete data.id

        return await axios({
            method: PATCH_METHOD,
            url: `${BACKEND_URL}/users/${id}`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            timeout: 55000,
            data,
        }).then((updateResponse) => {
            notification.success({
                placement: 'bottomRight',
                message: 'Update Success',
                description: `Successfully updated user ${updateResponse.data.email}`,
                style: {backgroundColor: ERROR_COLOR}
            });
            return updateResponse;
        }).catch(handleError);
    }
    
    updateGroup = async (token, data) => {
        const id = data.id
        delete data.id

        return await axios({
            method: PATCH_METHOD,
            url: `${BACKEND_URL}/groups/${id}`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            timeout: 55000,
            data,
        }).then((updateResponse) => {
            notification.success({
                placement: 'bottomRight',
                message: 'Update Success',
                description: `Successfully updated group ${updateResponse.data.name}`,
                style: {backgroundColor: ERROR_COLOR}
            });

            return updateResponse;
        }).catch(handleError);
    }
    deleteGroup = async (token, groupId) => {
        return await axios({
            method: DELETE_METHOD,
            url: `${BACKEND_URL}/groups/${groupId}`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            timeout: 55000,
        }).then((deleteResponse) => {
            notification.success({
                placement: 'bottomRight',
                message: 'Delete Success',
                description: 'Successfully deleted group.',
                style: {backgroundColor: ERROR_COLOR}
            });
            return deleteResponse;
        }).catch(handleError);
    }
    
    deleteUser = async (token, userId) => {
        return await axios({
            method: DELETE_METHOD,
            url: `${BACKEND_URL}/users/${userId}`,
            headers: {'Authorization': `Bearer ${token}`},
            timeout: 55000,
        }).then((deleteResponse) => {
            notification.success({
                placement: 'bottomRight',
                message: 'Delete Success',
                description: 'Successfully deleted user.',
                style: {backgroundColor: ERROR_COLOR}
            });
            return deleteResponse;
        }).catch(handleError);
    }

    createGroup = async (token, data) => {
        return await axios({
            method: POST_METHOD,
            url: `${BACKEND_URL}/groups`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            timeout: 55000,
            data,
        }).then((updateResponse) => {
            notification.success({
                placement: 'bottomRight',
                message: 'Create Success',
                description: `Successfully created group ${data.name}.`,
                style: {backgroundColor: ERROR_COLOR}
            });

            return updateResponse;
        }).catch(handleError);
    }

    getUsers = async (token) => {
        return await axios({
            method: GET_METHOD,
            url: `${BACKEND_URL}/users`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            timeout: 55000,
        }).catch(handleError);
    }

    getGroups = async (token) => {
        return await axios({
            method: GET_METHOD,
            url: `${BACKEND_URL}/groups`,
            responseType: 'json',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            timeout: 55000,
        }).catch(handleError);
    }

    addUserToGroup = async (token, userId, groupId) => {
        return await axios({
            method: POST_METHOD,
            url: `${BACKEND_URL}/user_groups/${userId}/${groupId}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            timeout: 55000,
        }).then((addResponse) => {
            notification.success({placement: 'bottomRight', message: 'Update Success', description: `Successfully added user ${addResponse.data.user.email} to group ${addResponse.data.group.name}`, style: {backgroundColor: ERROR_COLOR} })
            return addResponse;
        }).catch(handleError);
    }

    removeUserFromGroup = async (token, userId, groupId) => {
        return await axios({
            method: DELETE_METHOD,
            url: `${BACKEND_URL}/user_groups/${userId}/${groupId}`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            timeout: 55000,
        }).then((removeResponse) => {
            notification.success({
                placement: 'bottomRight',
                message: 'Remove Success',
                description: `Successfully removed user ${removeResponse.data.user.email} from group ${removeResponse.data.group.name}`,
                style: {backgroundColor: ERROR_COLOR}
            });
            return removeResponse;
        }).catch(handleError);
    }

    logout = async (token) => {
        const logout = await axios({
            method: GET_METHOD,
            url: `${BACKEND_URL}/logout`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        })
        return logout
    }

    getTreatments = async (token) => {
        return await axios({
            method: GET_METHOD,
            url: `${BACKEND_URL}/treatments`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            timeout: 55000,
        }).catch(handleError);
    }

    getExperimentCompounds = async (token) => {
        return await axios({
            method: GET_METHOD,
            url: `${BACKEND_URL}/experiment_compounds`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            timeout: 55000,
        }).catch(handleError);
    }

    getProteins = async (token) => {
        return await axios({
            method: GET_METHOD,
            url: `${BACKEND_URL}/proteins`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            timeout: 55000,
        }).catch(handleError);
    }

    getPeptideBoxPlotData = async (token, experimentId, accessionId, normalized) => {
        const params = {
            'experimentId': experimentId,
            'accessionId': accessionId,
        };

        if (normalized) params['normalized'] = normalized;

        return await axios({
            method: GET_METHOD,
            url: `${BACKEND_URL}/peptide_box_plot`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            timeout: 55000,
            params: params
        }).then(response => {
            return response.data;
        }).catch(handleError);
    }

    deleteExperiment = async (token, experimentId) => {
        return await axios({
            method: DELETE_METHOD,
            url: `${BACKEND_URL}/experiments/${experimentId}`,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            timeout: 55000,
        }).then(deleteResponse => {
            notification.success({placement: 'bottomRight', message: 'Delete Success', description: 'Delete request recieved.', style: {backgroundColor: ERROR_COLOR} })
            return deleteResponse
        }).catch(handleError);
    }
}


export default new ApiService()